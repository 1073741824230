<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>
      <a-row class="flex-no__wrap" type="flex">
        <a-button type="primary" @click="modalVisible = true">
          <a-icon type="plus" />{{ $t("Add") }}</a-button
        >
      </a-row>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <template slot="planery" slot-scope="item">
        {{ item.name }} - {{ $t("PlenarySession") }}
      </template>
      <template slot="date" slot-scope="item">
        {{ item.start_date }}{{ item.end_date ? "-" + item.end_date : "" }}
      </template>
      <template slot="link" slot-scope="item">
        <a :href="item.link" target="_blank">
          {{ item.link }}
        </a>
      </template>
      <template slot="operation" slot-scope="item">
        <a-row class="btns" type="flex" align="middle" justify="end">
          <a-button type="primary" class="edit-btn" @click="updateItem(item)">
            <a-icon type="edit" class="action-btns" />
          </a-button>
          <a-popconfirm
            :cancel-text="$t('No')"
            :ok-text="$t('Yes')"
            :title="$t('APopconfirm')"
            @confirm="removeItem(item.id)"
          >
            <a-button type="primary">
              <a-icon class="action-btns" type="delete" />
            </a-button>
          </a-popconfirm>
        </a-row>
      </template>
    </a-table>
    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+count || 0"
        @change="toPage"
      />
    </a-row>
    <a-modal
      :visible="modalVisible"
      :title="$t('Add')"
      :closable="false"
      width="700px"
      v-if="modalVisible"
    >
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          {{ $t("PlenarySession") }}:
        </a-col>
        <a-col :span="16">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-input
              v-model="form.name"
              type="number"
              :placeholder="$t('Placeholder')"
            />
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          {{ $t("IsDate") }}:
        </a-col>
        <a-col :span="16">
          <a-date-picker
            v-model="form.start_date"
            valueFormat="DD.MM.YYYY"
            format="DD.MM.YYYY"
            style="margin-right: 20px"
          />
          <a-date-picker
            v-model="form.end_date"
            valueFormat="DD.MM.YYYY"
            format="DD.MM.YYYY"
          />
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          {{ $t("YouTubeLink") }}:
        </a-col>
        <a-col :span="16">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-input v-model="form.link" :placeholder="$t('Placeholder')" />
          </a-row>
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 15px">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          {{ $t("TablePhoto") }}:
        </a-col>
        <a-col :span="16" class="sm-mb">
          <div class="form_field">
            <div class="meeting-image">
              <img :src="cover" />
            </div>
            <a-button
              style="font-size: 13px"
              type="primary"
              @click="uploadFile"
            >
              <a-icon style="font-size: 16px" type="upload" />{{
                $t("UploadImage")
              }}</a-button
            >
            <span v-if="!cover" style="padding-left: 8px"
              >Картинка не выбрана</span
            >
            <input
              ref="fileInput"
              class="UploadhideInput"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/svg"
              @change="handleFile"
            />
          </div>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="closeModal">{{ $t("Close") }}</a-button>
        <a-button
          key="save"
          type="primary"
          class="edit-btn"
          @click="createItem"
          >{{ $t("Save") }}</a-button
        >
      </template>
    </a-modal>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        start_date: null,
        end_date: null,
        link: "",
        cover: null,
      },
      cover: null,
      list: [],
      loading: false,
      columns: [
        {
          title: this.$t("PlenarySession"),
          key: "planery",
          scopedSlots: { customRender: "planery" },
          width: "20%",
        },
        {
          title: this.$t("IsDate"),
          key: "date",
          scopedSlots: { customRender: "date" },
          width: "22%",
        },
        {
          title: this.$t("Link"),
          key: "link",
          scopedSlots: { customRender: "link" },
          width: "40%",
        },
        {
          title: "",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      modalVisible: false,
      perPage: 20,
      page: 1,
      offset: 0,
      count: 0,
    };
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true,
    },
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click();
    },
    handleFile(e) {
      console.log(e.target.files[0])
      this.cover = URL.createObjectURL(e.target.files[0]);
      this.form.cover = e.target.files[0];
    },
    async fetchData() {
      try {
        this.loading = true;
        this.offset = +this.$route.query.page || 1;
        this.page = this.offset;
        this.offset = (this.offset - 1) * this.perPage;
        const { data } = await this.$api.get("/admin/meeting/list", {
          params: {
            category: this.$route.params.category,
            limit: this.perPage,
            offset: this.offset,
          },
        });
        this.list = (data && data.data.results) || [];
        this.count = (data.data && data.data.count) || 0;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } });
    },
    async createItem() {
      try {
        this.form.end_date = this.form.end_date ? this.form.end_date : null;
        if (this.form.id) {
          let f = new FormData();
          this.form.cover && f.append("cover", this.form.cover);
          Object.keys(this.form).forEach((key) => {
            this.form[key] && key != "cover" && f.append(key, this.form[key]);
          });
          let { data } = await this.$api.put(
            `/admin/meeting/${this.form.id}`,
            f
          );
          if (data.status) {
            await this.fetchData();
            this.$message.success("Успешно");
            this.closeModal();
          } else {
            this.$message.error(data.message);
          }
        } else {
          let f = new FormData();
          this.form.cover && f.append("cover", this.form.cover);
          Object.keys(this.form).forEach((key) => {
            this.form[key] && key != "cover" && f.append(key, this.form[key]);
          });
          let { data } = await this.$api.post("/admin/meeting/create", f);
          if (data.status) {
            await this.fetchData();
            this.$message.success("Успешно");
            this.closeModal();
          } else {
            this.$message.error(data.message);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async removeItem(id) {
      try {
        const { data } = await this.$api.delete(`/admin/meeting/${id}`);
        if (data.status) {
          this.$message.success("Успешно удалена");
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateItem(item) {
      this.form.id = item.id;
      this.form.name = item.name;
      this.form.start_date = item.start_date;
      this.form.end_date = item.end_date;
      this.form.link = item.link;
      this.cover = item.cover;
      this.modalVisible = true;
    },
    closeModal() {
      this.cover = null;
      this.modalVisible = false;
      this.form = {
        name: "",
        start_date: null,
        end_date: null,
        link: "",
      };
    },
  },
};
</script>
<style>
.meeting-image {
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
}
.meeting-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
